import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartItems:any[] = [];
  cartData = new BehaviorSubject<number>(0)

  constructor() {
    this.loadCartItems();
   }

  saveCartItems() {
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    this.getCartLength()
  }
  loadCartItems() {
    const data = localStorage.getItem('cartItems');
    if (data) {
      this.cartItems = JSON.parse(data);
    }
  }
  addToCart(product:any){
    let existingItem = this.cartItems.find(item => item.id === product.id);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        this.cartItems.push({ ...product, quantity: 1 });
      }
    this.saveCartItems();
  }
  removeFromCart(index: number){
    this.cartItems.splice(index, 1);
    this.saveCartItems();
  }
  clearCart() {
    this.cartItems = [];
    this.saveCartItems();
  }
  getCartLength(){
    let data = this.cartItems.length
    this.cartData.next(data)
  }
}
