<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
	<div class="container-fluid">
		<a class="navbar-brand" [routerLink]="'.'">Ecomm</a>
		<button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>
		<div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
			<ul class="navbar-nav ms-auto">
				<li class="nav-item active">
					<a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">Products</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">Gallery</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'cart/cart-view'" (click)="isMenuCollapsed = true">Cart({{cartLength}})</a>
				</li>
			</ul>
		</div>
	</div>
</nav>
