import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isMenuCollapsed = true;
  cartLength:number = 0
  constructor(private cart : CartService) {
    this.cart.saveCartItems();
    this.cart.cartData.subscribe((res)=>{
      this.cartLength = res
    })
   }

  ngOnInit(): void {
  }

}
